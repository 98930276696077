<template>
	<div class="NotFoundView flex flex--column" :class="elmClasses">
		
		<!-- dev grid -->
		<MhDevGrid
			innerClasses="maxWidth maxWidth--sectionInner"
			color="blue"
			opacity="0.25"
			columns="8"
			gap="30px"
		></MhDevGrid>
		
		<!-- wrapper keeps the footer offscreen -->
		<div class="minHeight XXXminHeight--100vh">
			
			<!-- mainHeader -->
			<GmSection class="app__nav GmSection--noMaxWidth" v-if="viewIsReady">
				<GmMainHeader></GmMainHeader>
			</GmSection>				
			
			<!-- 404 -->
			<GmSection>			
				<Gm404 />
			</GmSection>
		
		</div>
	
		<!-- GmMainFooter -->
		<GmSection			
			:section="{ bgColor : app.acfOptions.footerBgColor }"
			v-if="app.acfOptions && viewIsReady">						
			<GmMainFooter
				:firstText="app.acfOptions.footerFirstCol"
				:secondText="app.acfOptions.footerSecondCol"
				:thirdText="app.acfOptions.footerThirdCol"
				:fourthText="app.acfOptions.footerFourthCol"				
			></GmMainFooter>
		</GmSection>
		
	</div>
</template>

<script>
	// @ is an alias to /src
	import { EventBus } from '@/event-bus.js'
	import ViewMixin from './View.mixin.js'
	import MhDevGrid from '@/components/MhDevGrid/MhDevGrid.vue'
	
	import GmMainHeader from '@/components/GmMainHeader.vue'
	import GmMainFooter from '@/components/GmMainFooter.vue'
	import GmSection from '@/components/GmSection.vue'
	import Gm404 from '@/components/Gm404.vue'

	export default {
		name: 'NotFoundView',
		components: {
			MhDevGrid,
			GmMainHeader,
			GmMainFooter,
			GmSection,
			Gm404,
		},
		mixins: [ 
			ViewMixin,
		],
		props: {},
		data() {
			return {}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},	
			elmClasses(){
				let classes = []
				
				return classes	
			},		
		},
		methods: {},		
		mounted() {
			this.viewIsReady = true
		}
	}
</script>

<style lang="less">	
	@import "../less/vars.less";
	@import "../less/atoms.less";

	.NotFoundView {
		position: relative;
	}
</style>
